.advisors-screen{
    .select-drop{
        min-width: 150px;
    }
     .uaermanage_group{
        margin-top: 15px;
        padding-top: 15px;
        display:  grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 18px;
    }

}
//view-advisor 
.view-advisor{
    .name-head{
        h5{
            font-size:  $xxlarge;
            color: #2A3547;
            font-weight: $medium; 
        }
    }
    .manage-card {
        .profile{
            @include box-size(55px, 55px);
            span{
                font-size: $xxlarge;
                font-weight: $semibold;
            }
        }
        .crd-details {
            grid-template-columns: repeat(4, 1fr);
            padding-bottom: 35px;
        }
    }
    .advisor-body{
        padding: 35px;
    }
    .view-status{
        min-width: 110px;
        padding: 7px 15px;
        text-align: center;
        @include borderRadius_30;
        span{
            color: #fff;
            font-weight: $medium;
            font-size: $lite;
        }
        &.active{
            background: $useractive-bg;
            span{
                color: $useractive-txt;
            }
        }
        &.inactive{
            background: $userinactive-bg;
            span{
                color: $userinactive-txt;
            }
        }
    }
}
.text-area{
    height: 100px;
}

// Progress Bar 
.progress-item{
    display: flex;
    align-items: center;
    grid-gap: 15px;
    margin-bottom: 10px;
    .progress{
        width: 100%;
    }
    .rating-count{
        display: flex;
        align-items: center;
        p{
            margin: 0;
            font-size: 16px;
            padding-right: 10px;
            width: 18px;
            line-height: 16px;
        }
        img{
            width: 16px;
        }
    }
}
.overall-rating {
    display: flex;
    align-items: center;
    grid-gap: 30px;
    padding-bottom: 30px;
    .progress-group{
        width: 80%;
    }
    .rating-total{
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        h4{
            font-weight: 600;
            color: #393939;
            margin: 0;
            font-size: 26px;
            
        }
        span{
            font-size: 14px;
            color: #999;
        }
    }
}

.comments-group{
    padding-top: 35px;
    padding-left: 25px;
    padding-right: 25px;
    .cmt-head {
        display: flex;
        align-items: center;
        grid-gap: 13px;
        span{
            color: #fff;
            padding: 4px 12px;
            width: 50px;
            background-color: #4AA752;
            border-radius: 30px;
        }
        h4{
            margin: 0;
            font-size: 23px;
            font-weight: 500;
        }
    }
    .cmt-body {
        padding-top: 10px;
        p{
            margin: 0;
            font-size: 16px;
            padding-bottom: 10px;
        }
    }
}
.comments-items{
    border-bottom: 1px solid #cccccca3;
    padding: 13px 0;
    &:last-child{
        border-bottom: none;
    }
    &:first-child{
        padding-top: 0;
    }
}

.advisor-card-bottom {
    display: flex;
    justify-content: space-between;
    
    .like-dislike {
        display: flex;
        align-items: center;
        padding-top: 10px;
        width: 100%;
        justify-content: flex-end;
    }
}

.descriptions {
    .date-time {
        margin: 0;
        text-align: right;
        padding-right: 15px;
    }
}

.view-image {
    .image {
        width: 100%;
    }
}