.dashboard-page {
    .card {
        margin-bottom: 24px;
        background: #fff;
        border-radius: 15px;
        max-height: 315px;
    }

    .card-design {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: 35px;

    }

    .manage-card {
        .profile{
            @include box-size(50px, 50px);
            padding: 10px;
            img{
                @include box-size(30px, 30px);

            }
        }
        .card-detail {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .number-count {
                font-size: 23px;
                font-weight: 700;
            }

            .arrow-rightView {
                cursor: pointer;
                background: #F2F5F7;
                border: 0;
                color: #000;
                font-size: 20px;
                padding: 5px;
                @include box-size(30px, 30px);
                @include borderRadius_5;
                @include flex-center-vert;

                &:hover {
                    color: #000;
                    background: #F2F5F7 !important;
                }
            }
        }
    }


    .marketto {
        padding: 30px;
        height: max-content;

        .meter-header {

            display: flex;
            grid-gap: 10px;
            align-items: center;
            justify-content: space-between;

            p {
                span{
                    font-weight: 600;
                }
                font-size: 14px;
                line-height: 18px;
            }
        }

        .meter-chart {
            margin: 0 auto;
        }
    }

    .buy-suggestion {
        BUTTON {
            border-radius: 30px;
            padding: 3px 15px;
            color: #fff;
            background-color: green;
            margin-top: 20px;
            //   font-size: $fs-micro;
            //   font-weight: $regular;
        }
    }

    .sell-suggestion {
        BUTTON {
            padding: 3px 15px;
            border-radius: 30px;
            color: #fff;
            background-color: red;
            margin-top: 20px;
            //   font-size: $fs-micro;
            //   font-weight: $regular;
        }
    }

    .rating {
        padding: 30px;
        .rating-card {
            margin-bottom: 24px;
            background: #fff;
            border-radius: 15px;
            max-width: 400px;
        }
        .star-list {
            @include flex-center;
            padding-bottom: 7px;

            .count-star {
                display: flex;
                justify-content: space-between;
                min-width: 27px;
                margin-right: 10px;

                .star-image {
                    width: 13px;

                    img {
                        width: 100%;
                    }
                }
            }

            &.star-5 {
                .progress {
                    .progress-bar {
                        background: $five-star;
                    }
                }
            }

            &.star-4 {
                .progress {
                    .progress-bar {
                        background: $four-star;
                    }
                }
            }

            &.star-3 {
                .progress {
                    .progress-bar {
                        background: $three-star;
                    }
                }
            }

            &.star-2 {
                .progress {
                    .progress-bar {
                        background: $two-star;
                    }
                }
            }

            &.star-1 {
                .progress {
                    .progress-bar {
                        background: $one-star;
                    }
                }
            }
        }

        .progress {
            width: 100%;
            height: 8px;
            background: $progress;

            .progress-bar {
                border-radius: 30px;
            }
        }

        .total-rating {
            .rating-count {
                text-align: center;
            }

            .rating-percentage {
                text-align: center;
            }

            .overall-count {
                display: flex;
                align-items: center;
                padding-top: 12px;
                margin-top: 12px;

                .star-image {
                    width: 18px;
                    margin-left: 8px;

                    img {
                        width: 100%;
                    }
                }

                h1 {
                    font-size: 45px;
                    font-weight: 700;
                }
            }

        }
    }

}