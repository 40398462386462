.reviewCard {
    background: $whiteColor;
    padding: 25px;
    border-radius: 15px;
    overflow: hidden;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        .avather {
            display: flex;
            align-items: center;

            img {
                width: 23px;
                height: 23px;
                border-radius: 50px;
            }
        }

        .name {
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            color: $gray05;
            margin-left: 8px !important;
        }
    }

    .body-content {
        .textContent {
            color: $cardTextColor;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-overflow: ellipsis;
            max-height: 70px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            // @supports (-webkit-line-clamp: 3) {
            //     overflow: hidden;
            //     text-overflow: ellipsis;
            //     white-space: initial;
            //     display: -webkit-box;
            //     -webkit-line-clamp: 3;
            //     -webkit-box-orient: vertical;
            //     height: 50px;
            // }

        }

        .authDetails {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: $lightgray;

            a {
                color: $userTextColor;
                text-decoration: underline !important;
            }
        }
    }

    .actionSection {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .greenButton {
            background: transparent;
            border: 1px solid $primary-color;
            color: $primary-color;

            &:hover {
                background: $primarygradient !important;
                color: $white !important;
            }
        }

        button {
            height: 36px;
            font-size: 14px;

            img {
                margin-right: 5px;
            }
        }
    }
}

.review {
    .cardContent {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
        grid-template-rows: auto;

        @media (max-width: 1350px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }
}

.review-model {
    .modal-content {
        padding: 0;
    }

    .modal-header {
        padding: 20px 32px;
        border-bottom: 1px solid $linegrey;
    }

    .page-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $gray04;
    }

    .modal-body {
        border-bottom: 1px solid $linegrey;

        p {
            @extend .page-title
        }

        h6 {
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
        }

        .reviewDetails {
            padding: 20px 32px !important;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 5px;
            grid-template-rows: auto;

            .info {
                display: flex;
                align-items: center;

                img {
                    margin-right: 5px;
                    width: 23px;
                    height: 23px;
                }

            }
        }

        .overRating {
            padding: 20px 32px;
            background: $grey06;
            // display: flex;

            h5 {
                margin: 0;
                margin-right: 5px;
            }
        }

        max-height: 300px;
        overflow-y: auto;

        .reViewContent {
            padding: 20px 32px;
            overflow: auto;
            overflow-wrap: break-word;
            max-height: 300px;
            text-wrap: wrap;
        }

    }

    .modal-footer {
        padding: 20px 32px !important;
        justify-content: flex-start;

        button {
            max-height: 36px;
            font-size: 14px;
        }
    }

}